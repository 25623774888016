import React from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { BsPeople } from "react-icons/bs";
import { FaFileCode } from "react-icons/fa";
import { IoMdChatboxes } from "react-icons/io";
import { FaLink } from "react-icons/fa6";

const Whatsappapi = () => {
  return (
    <div>
      <Header />
      <div className="banner">
        <img
          src="assets/img/whatsappapi.png"
          alt="cybersecurity"
          style={{
            height: "380px",
            width: "100%",
            objectFit: "contain",
            backgroundColor: "#BAE8E8",
          }}
        ></img>
      </div>

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 hero-img d-flex justify-content-center"
              data-aos-delay="200"
            >
              <img
                src="assets/img/images/whatsapp1.png"
                style={{ borderRadius: "20px", height: "400px" }}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center text-center">
              <p data-aos-delay="400" style={{ fontSize: "40px" }}>
                <b>
                  INCREASE YOUR REVENUE BY 60% WITH{" "}
                  <span
                    style={{
                      backgroundColor: "#FFDD59",
                      padding: "5px 0px 5px 5px",
                    }}
                  >
                    Branding World{" "}
                  </span>
                  <span
                    style={{
                      color: "#4154f1",
                      backgroundColor: "#FFDD59 ",
                      padding: "5px 5px 5px 0px",
                    }}
                  >
                    {" "}
                    CIRCLE
                  </span>{" "}
                  WHATSAPP SUITE.
                </b>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div
        id="hero"
        className="her d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container text-center" style={{ width: "1000px" }}>
          <h1 className="py-5">
            <b>How Whatsapp API helps in increase revenue</b>
          </h1>
          <div className="row px-3">
            <div className="col-lg-6 hero-img d-flex pb-3">
              <img
                src="assets/img/images/picture1.png"
                style={{
                  borderRadius: "20px",
                  height: "80px",
                  paddingLeft: "5px",
                }}
                className="img-fluid"
                alt=""
              />
              <p
                className="d-flex justify-content-center align-items-center"
                style={{ fontSize: "25px", textAlign: "left" }}
              >
                Broad cast Promotional offers
              </p>
            </div>
            <div className="col-lg-6 hero-img d-flex pb-3">
              <img
                src="assets/img/images/picture2.png"
                style={{ borderRadius: "20px", height: "80px" }}
                className="img-fluid"
                alt=""
              />
              <p
                className="d-flex justify-content-center align-items-center"
                style={{ fontSize: "25px", textAlign: "left" }}
              >
                Recover Abandoned Cart and lost Deals
              </p>
            </div>
            <div className="col-lg-6 hero-img d-flex  pb-3">
              <img
                src="assets/img/images/picture3.png"
                style={{ borderRadius: "20px", height: "80px" }}
                className="img-fluid"
                alt=""
              />
              <p
                className="d-flex justify-content-center align-items-center"
                style={{ fontSize: "25px", textAlign: "left" }}
              >
                Run Click to WhatsApp Ads on Meta
              </p>
            </div>
            <div className="col-lg-6 hero-img d-flex pb-3">
              <img
                src="assets/img/images/picture4.png"
                style={{ borderRadius: "20px", height: "80px" }}
                className="img-fluid"
                alt=""
              />
              <p
                className="d-flex justify-content-center align-items-center"
                style={{ fontSize: "25px", textAlign: "left" }}
              >
                Automate your marketing, sales and support
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        id="hero"
        className="her d-flex align-items-center"
        style={{ backgroundColor: "#a3e6ff" }}
      >
        <div className="container text-center">
          <div className="py-3">
            <h1>
              <b>Features</b>
            </h1>
            <p>Smart tools for efficient and personalized communication</p>
          </div>

          <div className="row px-1">
            <div className="col-lg-6 hero-img d-flex pb-3 ">
              <div
                className=" hero-img d-flex pb-3  d-flex justify-content-center align-items-center"
                style={{
                  border: "2px solid",
                  borderRadius: "20px",
                  backgroundColor: "hsl(240 45% 98%)",
                }}
              >
                <div className="col-lg-2">
                  <HiOutlineSpeakerphone
                    style={{ fontSize: "80px", color: "#af7ef6 " }}
                  />
                </div>
                <div className="col-lg-10  ">
                  <p style={{ fontSize: "25px", textAlign: "left" }}>
                    <b>Broadcast Communication</b>
                  </p>
                  <p style={{ fontSize: "20px", textAlign: "left" }}>
                    Push out your compaings and engage with high response
                    WhatsApp message
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 hero-img d-flex pb-3 ">
              <div
                className=" hero-img d-flex pb-3  d-flex justify-content-center align-items-center"
                style={{
                  border: "2px solid",
                  borderRadius: "20px",
                  backgroundColor: "hsl(240 45% 98%)",
                }}
              >
                <div className="col-lg-2">
                  <IoChatboxEllipsesOutline
                    style={{ fontSize: "80px", color: "green" }}
                  />
                </div>
                <div className="col-lg-10  ">
                  <p style={{ fontSize: "25px", textAlign: "left" }}>
                    <b>Chatbots</b>
                  </p>
                  <p style={{ fontSize: "20px", textAlign: "left" }}>
                    Create no-code chatbots to provide instant responses to
                    common request
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row px-1">
            <div className="col-lg-6 hero-img d-flex pb-3 ">
              <div
                className=" hero-img d-flex pb-3  d-flex justify-content-center align-items-center"
                style={{
                  border: "2px solid",
                  borderRadius: "20px",
                  backgroundColor: "hsl(240 45% 98%)",
                }}
              >
                <div className="col-lg-2">
                  <BsPeople style={{ fontSize: "80px", color: "#4154f1 " }} />
                </div>
                <div className="col-lg-10  ">
                  <p style={{ fontSize: "25px", textAlign: "left" }}>
                    <b>Multiple agents</b>
                  </p>
                  <p style={{ fontSize: "20px", textAlign: "left" }}>
                    Share the wait across your team with support for an
                    unlimited number of agents
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 hero-img d-flex pb-3 ">
              <div
                className=" hero-img d-flex pb-3  d-flex justify-content-center align-items-center"
                style={{
                  border: "2px solid",
                  borderRadius: "20px",
                  backgroundColor: "hsl(240 45% 98%)",
                }}
              >
                <div className="col-lg-2">
                  <FaFileCode style={{ fontSize: "80px", color: "#af7ef6" }} />
                </div>
                <div className="col-lg-10  ">
                  <p style={{ fontSize: "25px", textAlign: "left" }}>
                    <b>Low-Code Automations</b>
                  </p>
                  <p style={{ fontSize: "20px", textAlign: "left" }}>
                    Simplify mass communication and personalized responses with
                    automated tools
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row px-1">
            <div className="col-lg-6 hero-img d-flex pb-3 ">
              <div
                className=" hero-img d-flex pb-3  d-flex justify-content-center align-items-center"
                style={{
                  border: "2px solid",
                  borderRadius: "20px",
                  backgroundColor: "hsl(240 45% 98%)",
                }}
              >
                <div className="col-lg-2">
                  <IoMdChatboxes
                    style={{ fontSize: "80px", color: "rgb(246 196 126) " }}
                  />
                </div>
                <div className="col-lg-10  ">
                  <p style={{ fontSize: "25px", textAlign: "left" }}>
                    <b>1-2-1 Communication</b>
                  </p>
                  <p style={{ fontSize: "20px", textAlign: "left" }}>
                    Tag contacts, categorize and target them in groups using
                    personalized communications.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 hero-img d-flex pb-3 ">
              <div
                className=" hero-img d-flex pb-3  d-flex justify-content-center align-items-center"
                style={{
                  border: "2px solid",
                  borderRadius: "20px",
                  backgroundColor: "hsl(240 45% 98%)",
                }}
              >
                <div className="col-lg-2">
                  <FaLink style={{ fontSize: "80px", color: "green" }} />
                </div>
                <div className="col-lg-10  ">
                  <p style={{ fontSize: "25px", textAlign: "left" }}>
                    <b>Easy integrations</b>
                  </p>
                  <p style={{ fontSize: "20px", textAlign: "left" }}>
                    Pre-bulit integrations let you Integrate with your tech
                    stack and configure in a few clicks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <h1
          style={{
            fontSize: "50px",
            lineHeight: "60px",
            color: "#012970",
            textAlign: "center",
            marginTop: "100px",
          }}
        >
          Whatsapp Business API
        </h1>

        <h5 style={{ textAlign: "center" }}>
          Grow your business on
          <span style={{ color: "hsl(249  100%  61%)", fontWeight: "800" }}>
            WhatsApp
          </span>
        </h5>

        {/* <div className="row"> */}
        <p data-aos-delay="400" style={{ textAlign: "justify" }}>
          The WhatsApp Business API allows businesses to respond to customers'
          messages, send notifications, and provide customer support. It's
          typically used by larger enterprises or those working with approved
          WhatsApp Business Solution Providers.
        </p>
        <div style={{ maxWidth: "800px", margin: "0 auto" }}>
          <h2 style={{ textAlign: "center" }}>VAC Whatsapp Connect Features</h2>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Feature</th>
                <th>Engage Plan</th>
                <th>Elite Plan</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Agent Transfer & Manager Monitoring</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Broadcasting & Retargeting</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Click-to-WhatsApp Ads Manager</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Dialogflow Chatbot Integration</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Multi-Agent Live Chat</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Shared Team Inbox</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Integrations with 3rd party software</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Smart Audience Segregation</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Template Message APIs</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Unlimited Agent Logins</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Tags</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Single Message Campaign</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Direct recharge with Meta</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Smart Agent Routing</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Chat status like open or closed</td>
                <td></td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Followup</td>
                <td></td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>DND List</td>
                <td></td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Campaign Budget Analytics</td>
                <td></td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Campaign Scheduler</td>
                <td></td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Project APIs</td>
                <td></td>
                <td>Yes</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1 ">
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Send Messages </b> Our VAC Whatsapp connect helps you to send
                text, images, videos, documents, and other media files to
                individual users or groups.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Receive Messages </b> Receive and process incoming messages
                from users, including text messages, media files, and other
                content.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Notifications</b>
                Send notifications to users about important updates, reminders,
                or other events
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Message Templates</b>Create predefined message templates for
                common use cases such as order confirmations, appointment
                reminders, or shipping updates.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Authentication </b> Authenticate users and ensure secure
                communication between your application and the messaging
                platform.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>User Profile Information</b> Access basic user profile
                information such as name, profile picture, and status.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Group Management</b> Create, manage, and interact with groups
                of users, including adding or removing participants, changing
                group settings, etc.
              </p>
            </div>
            <div
              className="col-lg-6 hero-img order-1 order-lg-2"
              data-aos-delay="200"
            >
              <img
                src="assets/img/whatsapp1.jpg"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 hero-img" data-aos-delay="200">
              <img
                src="assets/img/whatsapp2.avif"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Analytics and Reporting</b> Access analytics and reporting
                data to track message delivery, user engagement, and other
                metrics.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Integration with Other Services </b> Integrate messaging
                functionality with other services such as CRM systems,
                e-commerce platforms, or customer support tools.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Automation</b>Implement automation features such as chatbots
                to handle common queries or tasks without human intervention.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Multi-language Support</b>
                Support for sending and receiving messages in multiple languages
                to cater to diverse user bases.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Security and Compliance</b>Ensure compliance with privacy
                regulations and implement security measures to protect user data
                and prevent unauthorized access.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                These are just some of the features commonly offered by
                messaging APIs. The specific features available may vary
                depending on the platform and the use case.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="services">
        <div className="container">
          <section
            id="hero"
            className="hero d-flex align-items-center"
            style={{ backgroundColor: "hsl(240 45% 98%)" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 hero-img" data-aos-delay="200">
                  <img
                    src="assets/img/whatsapp.avif"
                    style={{ borderRadius: "20px" }}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  <h5 style={{ color: "rgb(124, 65, 251)" }}>
                    Personalize communication and sell more with the WhatsApp
                    Business API platform that automates marketing, sales,
                    service and support.
                  </h5>
                  <br></br>
                  <h5 style={{ color: "rgb(124, 65, 251)" }}>
                    Our objective is to ensure that Professionals, Tiny Units,
                    MSMEs and Corporates are using this platform seamlessly with
                    their existing calling platform and CRM / ERP
                  </h5>
                  <br></br>
                  <ul>
                    <li>Send bulk campaigns</li>
                    <li>Automate notifications</li>
                    <li>Have Multiple users</li>
                    <li>
                      Based on key words divert messages to the respective
                      executives automatically
                    </li>
                    <li>Chatbot to answer questions</li>
                    <li>Get real-time delivery insights with MIS</li>
                    <li>reports & Analytics</li>
                    <li>
                      Send updates, delivery reminders, payment reminders &
                      other notifications using our APIs
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      <section id="recent-blog-posts" class="recent-blog-posts">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img
                    src="assets/img/boardcast.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <h2>Broadcast</h2>
                <ul>
                  <li>
                    Push out your campaigns and engage with high response
                    WhatsApp messages.
                  </li>
                  <li>
                    Tag contacts, categorize and target them in groups using
                    personalized communications.
                  </li>
                  <li>
                    Build and nurture the relationships that generate future
                    sales by engaging with your audiences regularly on WhatsApp.
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img
                    src="assets/img/chatbot.webp"
                    style={{ height: "260px", width: "500px" }}
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <h2>Chatbots</h2>
                <ul>
                  <li>
                    Create no-code chatbots to provide instant responses to
                    common requests.
                  </li>
                  <li>
                    Simplify mass communication and personalized responses with
                    automated tools.
                  </li>
                  <li>
                    Boost sales by directly converting more customer
                    communications on WhatsApp.{" "}
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img
                    src="assets/img/blog/blog-3.jpg"
                    style={{ height: "260px", width: "500px" }}
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <h2>Shared Team Inbox</h2>
                <ul>
                  <li>
                    Share the inbox across your team and provide stellar support
                    on WhatsApp
                  </li>
                  <li>
                    Use pre-built integrations to bring in customer context in a
                    few clicks.
                  </li>
                  <li>
                    Push post sales communications such as order updates, and
                    resolve support requests quickly through WhatsApp.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Whatsappapi;
