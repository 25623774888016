import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { Link } from "react-router-dom";

import React, { useEffect } from "react";
import Isotope from "isotope-layout";
import Swiper from "swiper";
import GLightbox from "glightbox";

const ITconsulting = () => {
  useEffect(() => {
    const initIsotope = () => {
      let portfolioContainer = document.querySelector(".portfolio-container");
      if (portfolioContainer) {
        let portfolioIsotope = new Isotope(portfolioContainer, {
          itemSelector: ".portfolio-item",
          layoutMode: "fitRows",
        });

        let portfolioFilters = document.querySelectorAll(
          "#portfolio-flters li"
        );

        portfolioFilters.forEach((filter) => {
          filter.addEventListener("click", function (e) {
            e.preventDefault();
            portfolioFilters.forEach((el) => {
              el.classList.remove("filter-active");
            });
            this.classList.add("filter-active");

            portfolioIsotope.arrange({
              filter: this.getAttribute("data-filter"),
            });
          });
        });
      }
    };

    const initLightbox = () => {
      GLightbox({
        selector: ".portfokio-lightbox",
      });
    };

    const initSlider = () => {
      new Swiper(".portfolio-details-slider", {
        speed: 400,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      });
    };

    // Initialize Isotope
    initIsotope();

    // Initialize Lightbox
    initLightbox();

    // Initialize Slider
    initSlider();
  }, []);

  return (
    <div>
      <Header />

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)", minHeight: "700px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center  order-2 order-lg-1">
              <h1>
                WE BRING TIME-TESTED APPROACHES &amp; PROVEN METHODOLOGIES
              </h1>
              <br />
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                Digital Transformation Consulting Services We understand that
                digital transformation is not just about incorporating new
                technology into a business, but a disruptive change that alters
                almost everything.Our consulting services help you reinvent
                existing solutions, automate manual processes and models, enable
                data-driven decisions and manage change internally
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                Through our domain expertise and valuable experience, we have
                helped businesses ranging from SME’s to large-scale enterprises
                change their digital strategy by understanding their industry
                and tailor-made solutions.
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    href="/ContactUs"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    style={{ borderRadius: "50px" }}
                  >
                    <span style={{ padding: "10px 5px", borderRadius: "50px" }}>
                      FREE CONSULTATION
                    </span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 hero-img  order-1 order-lg-2"
              data-aos-delay="200"
            >
              <img
                src="assets/img/features.png"
                style={{
                  borderRadius: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section id="portfolio" className="portfolio">
        <div className="container">
          <header className="section-header">
            <p>Our Clients</p>
          </header>

          <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                {/* <li data-filter="*" className="filter-active">
                  All
                </li> */}
                <li data-filter=".filter-ecom">E-commerce</li>
                <li data-filter=".filter-manufacturing">Manufacturing</li>
                <li data-filter=".filter-retail">Retail</li>
                <li data-filter=".filter-trade">B2B Trading</li>
                <li data-filter=".filter-data">Data Science</li>
              </ul>
            </div>
          </div>

          <div
            className="row gy-4 portfolio-container ms-lg-5"
            style={{ textAlign: "center" }}
          >
            {/* Sample portfolio items */}
            <div className="col-lg-4 col-md-6 portfolio-item filter-ecom">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/pothys2.jpg"
                  style={{
                    height: "225px",
                    width: "225px",
                    border: "1px solid",
                  }}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-ecom">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/kalyan.jpg"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-manufacturing">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/elevate.png"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-manufacturing">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/ministerwhite.jpg"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-retail">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/pothys2.jpg"
                  style={{
                    height: "225px",
                    width: "225px",
                    border: "1px solid",
                  }}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-retail">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/kalyan.jpg"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-retail">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/elevate.png"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-trade">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/ministerwhite.jpg"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-trade">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/sdc.webp"
                  className="img-fluid"
                  style={{
                    height: "225px",
                    width: "225px",
                    border: "1px solid",
                  }}
                  alt=""
                />
              </div>
            </div>

            <div className="col-lg-4 col-md-6 portfolio-item filter-data">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/pothys2.jpg"
                  className="img-fluid"
                  style={{
                    height: "225px",
                    width: "225px",
                    border: "1px solid",
                  }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-data">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/kalyan.jpg"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-data">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/tecnimont.jpeg"
                  className="img-fluid"
                  style={{
                    height: "225px",
                    width: "225px",
                    border: "1px solid",
                  }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="hero"
        className="hero d-flex align-items-center  "
        style={{
          backgroundColor: "hsl(240 45% 98%)",
          justifyContent: "space-evenly",
          display: "flex",
        }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src="assets/img/features-2.png"
                style={{ height: "100px", width: "100px" }}
              ></img>
              <h1>IT Modernization</h1>
              <br></br>
              <ul>
                <li>Digitize core business processes</li>
                <li>Develop new models</li>
                <li>Implement state-of-the-art technology solutions</li>
                <li>Adopt advanced IT architectures</li>
                <li>Build collaborative technology teams</li>
              </ul>
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src="assets/img/hero-img.png"
                style={{ height: "100px", width: "100px" }}
              ></img>
              <h1>Digital Strategy</h1>
              <br></br>
              <ul>
                <li>Transform the way users interact with your brand</li>
                <li>
                  Map user journey and behavior patterns across operating
                  platforms
                </li>
                <li>Establish brand uniqueness and value</li>
                <li>Enhance customer engagement</li>
                <li>
                  Bridge gaps between the customer, and continuous development
                  of products and services
                </li>
                <li>Improve Information Architecture</li>
              </ul>
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src="assets/img/values-1.png"
                style={{ height: "100px", width: "100px" }}
              ></img>
              <h1>DigitalApplications</h1>
              <br></br>
              <ul>
                <li>
                  Redesign enterprise architecture in line with digital assets
                </li>
                <li>
                  Build omni-channel capabilities through advanced technology
                  solutions
                </li>
                <li>
                  Development of purpose-built applications for service-specific
                  requirements
                </li>
                <li>
                  Implement open-source technologies to develop robust and
                  scalable applications
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)", height: "700px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 hero-img" data-aos-delay="200">
              <img
                src="assets/img/pexels.jpg"
                style={{ borderRadius: "20px", marginTop: "70px" }}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h3>
                <b>
                  How Branding World Assist Your Business We Offer Unique
                  Services Tailored Specifically for Your Company.Innovative
                  Methods to Help Boost Your Company's Profits
                </b>
              </h3>
              <br></br>
              <h3 style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}>
                01. Discussion
              </h3>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                Before making a proposal, we meet with clients face-to-face to
                go through their specific requirements.
              </p>
              <h3 style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}>
                02. Ideas & concepts
              </h3>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                Our experts come up with all kinds of ideas and plans to make
                sure that the IT services chosen have the best solutions.
              </p>
              <h3 style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}>
                03. Testing & Trying
              </h3>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                After we all agree on the ideas and plans, we'll carry them out
                as planned and give feedback on the results and changes.
              </p>
              <h3 style={{ color: "rgb(124, 65, 251)", fontWeight: "800" }}>
                04. Execute & install
              </h3>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                Once the final blueprint has been accepted, everything will be
                carried out in accordance with the signed contract.
              </p>

              <div data-aos-delay="600"></div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ITconsulting;
