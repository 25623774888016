import React from "react";
import { FcCalculator } from "react-icons/fc";
import { PiFactory } from "react-icons/pi";
import { FaUsersGear } from "react-icons/fa6";
import { MdManageAccounts } from "react-icons/md";
import { FaCalculator } from "react-icons/fa";
import { IoPower } from "react-icons/io5";
import { IoMdCloudOutline } from "react-icons/io";
import { FaRegLightbulb } from "react-icons/fa6";
import { MdIntegrationInstructions } from "react-icons/md";
import { FaMobileScreenButton } from "react-icons/fa6";
import { FaDisplay } from "react-icons/fa6";
import { FaTruckMoving } from "react-icons/fa";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";

const Erp = () => {
  return (
    <div>
      <Header />
      {/* -------------------------------------------erp-banner--------------------------------------------- */}
      <div className="erp-banner">
        <img src="assets/img/erp/erp-banner.webp" alt="img"></img>
      </div>
      <div class="erp-OPTIMIZING ">
        <div class="hexagon-1">
          <img src="assets/img/erp/erp-optimized.png" alt="img"></img>
        </div>
        <div className="erp-optimizing-right">
          <h1>OPTIMIZING OPERATIONS WITH REAL-TIME VISIBILITY</h1>
          <p>
            Streamline business processes and boost agility with our
            user-friendly, scalable enterprise software.
          </p>
        </div>
      </div>
      {/* -------------------------------------------erp-Digitization--------------------------------------------- */}
      <div className="erp-Digitization">
        <p>
          Digitization is transforming businesses, and as robots enter the front
          office, enterprises must adopt radical strategies to thrive.
          Traditional ERP software is no longer sufficient for operational
          improvement. Born-in-the-cloud ERP solutions are now redefining the
          digital backbone for organizations committed to innovation and success
          in today’s volatile, uncertain, and complex market.
        </p>
      </div>
      {/* -------------------------------------------erp-Unified--------------------------------------------- */}
      <div className="erp-Unified container">
        <h1>An Unified Platform to Manage All our requirements</h1>
        <div className="erp-Unified-icons">
          <div className="erp-Unified-level-1">
            <FaCalculator style={{ fontSize: "60px", color: "#a066cb" }} />

            <p>Finance & Accounting</p>
          </div>
          <div className="erp-Unified-level-1">
            <i
              class="fa-solid fa-truck-fast"
              style={{ fontSize: "60px", color: "#a066cb" }}
            ></i>
            <p>Supply Chain Management</p>
          </div>
          <div className="erp-Unified-level-1">
            <PiFactory style={{ fontSize: "60px", color: "#a066cb" }} />
            <p>Enterprise asset Management</p>
          </div>
          <div className="erp-Unified-level-1">
            <MdManageAccounts style={{ fontSize: "60px", color: "#a066cb" }} />
            <p>Project management</p>
          </div>
        </div>
        <div className="erp-Unified-icons">
          <div className="erp-Unified-level-1">
            <i
              class="fa-solid fa-gears"
              style={{ fontSize: "60px", color: "#a066cb" }}
            ></i>
            <p>Productions & Manufacturing</p>
          </div>
          <div className="erp-Unified-level-1">
            <FaUsersGear style={{ fontSize: "60px", color: "#a066cb" }} />
            <p>Quality Management</p>
          </div>
          <div className="erp-Unified-level-1">
            <i
              class="fa-solid fa-comments-dollar"
              style={{ fontSize: "60px", color: "#a066cb" }}
            ></i>

            <p>HR & Payroll</p>
          </div>
        </div>
      </div>
      <div className="erp-Unified-bottom">
        <img src="assets/img/erp/erp-bottom.png"></img>
      </div>
      {/* -------------------------------------------erp-Software--------------------------------------------- */}
      <div className="erp-Software container">
        <h1>Why Choose Branding World ERP Software</h1>
        <div className="erp-Software-icons">
          <div className="erp-Software-level-1">
            <IoPower style={{ fontSize: "60px", color: "#a066cb" }} />
            <h1>Power of One</h1>
            <p>
              Build custom ERP software with a single codebase for seamless
              integration.
            </p>
          </div>
          <div className="erp-Software-level-1">
            <IoMdCloudOutline style={{ fontSize: "60px", color: "#a066cb" }} />
            <h1>Cloud/On-Premise</h1>
            <p>We provide the flexibility of choise for the customer</p>
          </div>
          <div className="erp-Software-level-1">
            <FaRegLightbulb style={{ fontSize: "60px", color: "#a066cb" }} />

            <h1>Real time Insigts</h1>
            <p>
              Get updates regarding day to day activites with real time data
            </p>
          </div>
        </div>
        <div className="erp-Software-icons">
          <div className="erp-Software-level-1">
            <MdIntegrationInstructions
              style={{ fontSize: "60px", color: "#a066cb" }}
            />
            <h1>Integration</h1>
            <p>
              Build custom ERP software with a single codebase for seamless
              integration.
            </p>
          </div>
          <div className="erp-Software-level-1">
            <FaMobileScreenButton
              style={{ fontSize: "60px", color: "#a066cb" }}
            />
            <h1>Mobility</h1>
            <p>
              Our integrated ERP software connects businesses 24/7 by providing
              real-time insights on the go
            </p>
          </div>
          <div className="erp-Software-level-1">
            <FaDisplay style={{ fontSize: "60px", color: "#a066cb" }} />

            <h1>User Interface</h1>
            <p>
              Research baed UI design maks the ERP very easy or anyone to use
            </p>
          </div>
        </div>
      </div>
      <div className="erp-Software-bottom">
        <img src="assets/img/erp/erp-bottom.png"></img>
      </div>
      {/* -------------------------------------------erp-footer--------------------------------------------- */}
      <div className="erp-footer">
        <div className="erp-footer-left">
          <div className="erp-footer-left-text">
            <h1>Industry We provide ERP</h1>
            <p>Take advantage of the tools available.</p>
          </div>
          <div className="erp-footer-left-image">
            <img src="assets/img/erp/erp-crystal.png"></img>
          </div>
        </div>
        <div className="erp-footer-right">
          <div className="erp-footer-right-level-1">
            <i
              class="fa-regular fa-hospital"
              style={{ fontSize: "90px", color: "#a066cb" }}
            ></i>

            <p>Hospital / Clinics / Pharmacy</p>
          </div>
          <div className="erp-footer-right-level-1">
            <i
              class="fa-regular fa-handshake"
              style={{ fontSize: "90px", color: "#a066cb" }}
            ></i>
            <p>Real Estate</p>
          </div>
          <div className="erp-footer-right-level-1">
            <PiFactory style={{ fontSize: "90px", color: "#a066cb" }} />

            <p>Manufacturing</p>
          </div>
          <div className="erp-footer-right-level-1">
            <FaDisplay style={{ fontSize: "90px", color: "#a066cb" }} />

            <p>IT/ITES</p>
          </div>
          <div className="erp-footer-right-level-1">
            <FaTruckMoving style={{ fontSize: "90px", color: "#a066cb" }} />

            <p>Logistics</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Erp;
