import React from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";

const Productengineering = () => {
  return (
    <div>
      <Header />

      {/* <section>
        <video
          src="assets/img/productengineering/Media1.mp4"
          className="col-12"
          style={{ width: "100%", height: "80vh" }}
          autoPlay
          loop
          muted
          aria-label="Product Engineering Video"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "assets/img/productengineering/Media1.mp4";
          }}
        >
          Your browser does not support the video tag.
        </video>
      </section> */}
      <div className="productengineering-main">
        <img
          src="assets/img/productengineering/engineeringbanner.jpg"
          alt="img"
        ></img>
        <div className="productengineering-main-text container">
          <h5>
            Transforming Vision into Innovation: Mastering Excellence in Product
            Engineering.
          </h5>
          <p>Shaping the Future with Today's Engineering Innovations</p>
        </div>
      </div>
      <div className="product-eng-headertext container">
        <h1 style={{ fontWeight: "bold" }} className="mb-3">
          What sets us apart
        </h1>
        <p>
          Our approach to product engineering is comprehensive. We go beyond
          technical aspects, taking into account market viability, user
          experience, and long-term scalability. By merging our technical
          expertise with a deep understanding of industry trends, we develop
          innovative, reliable, and impactful products.
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Strategic Partnership </span> We
          collaborate closely with your team to align our efforts with your
          business objectives, guaranteeing a partnership that exceeds
          expectations.
        </p>
        <h5>
          <span style={{ fontWeight: "bold" }}>ROI-Driven Approach </span> Our
          emphasis lies not only on innovation but also on crafting solutions
          that yield tangible returns and foster business growth.
        </h5>
        <h5>
          <span style={{ fontWeight: "bold" }}>Future-Ready Solutions </span> We
          develop products that are scalable, resilient, and ready to embrace
          future challenges in an ever-evolving digital landscape.
        </h5>
      </div>
      <div className="product-eng-headertext container">
        <h1 style={{ fontWeight: "bold" }}>
          Our Comprehensive Service Offerings
        </h1>
        <div className="product-Comprehensive">
          <div className="product-Comprehensive-card1">
            <div className="product-Comprehensive-card1-dia">
              <img
                src="assets/img/productengineering/productcard2.png"
                alt="img"
              ></img>
            </div>
            <p>End-to-End Product Development</p>
          </div>
          <div className="product-Comprehensive-card1">
            <div className="product-Comprehensive-card1-dia">
              <img
                src="assets/img/productengineering/productcard3.png"
                alt="img"
              ></img>
            </div>
            <p>Product Re-Engineering</p>
          </div>
          <div className="product-Comprehensive-card1">
            <div className="product-Comprehensive-card1-dia">
              <img
                src="assets/img/productengineering/productcard1.png"
                alt="img"
              ></img>
            </div>
            <p>Prototype & MVP Development</p>
          </div>
        </div>
      </div>

      <div className="product-Product-Development container">
        <h1 style={{ fontWeight: "bold" }}>End-to-End Product Development</h1>
        <p>
          From ideation to deployment, we manage the entire product lifecycle.
        </p>
        <div className="product-Product-Development-contain row col-lg-12 g-5 d-flex justify-content-center align-items-center ">
          <div className="product-Product-Development-card1 mx-3">
            <img
              src="assets/img/productengineering/endcard8.png"
              alt="img"
            ></img>

            <p>Conceptualization</p>
          </div>
          <div className="product-Product-Development-card1 mx-4">
            <img
              src="assets/img/productengineering/endcard3.png"
              alt="img"
            ></img>

            <p>Architecture & Solution Design</p>
          </div>
          <div className="product-Product-Development-card1 mx-4">
            <img
              src="assets/img/productengineering/endcard4.png"
              alt="img"
            ></img>

            <p>User Experience Design</p>
          </div>
          <div className="product-Product-Development-card1 mx-4">
            <img
              src="assets/img/productengineering/endcard5.png"
              alt="img"
            ></img>

            <p>Prototyping</p>
          </div>
          <div className="product-Product-Development-card1 mx-4">
            <img
              src="assets/img/productengineering/endcard6.png"
              alt="img"
            ></img>

            <p>Development & Testing</p>
          </div>
          <div className="product-Product-Development-card1 mx-4">
            <img
              src="assets/img/productengineering/endcard7.png"
              alt="img"
            ></img>

            <p>Deployment & Scaling</p>
          </div>

          <div className="product-Product-Development-card1 mx-4">
            <img
              src="assets/img/productengineering/endcard1.png"
              alt="img"
            ></img>

            <p>Post Go-Live Support</p>
          </div>
        </div>
      </div>
      <div className="product-Product-Development container ">
        <h1 style={{ fontWeight: "bold" }}>Product Re-Engineering</h1>
        <p>
          For products needing a modern upgrade, we enhance them while
          preserving their core functionalities.
        </p>
        <div className="product-Product-Development-contain row col-lg-12 g-5 d-flex justify-content-center align-items-center ">
          <div className="product-Product-Development-card1 mx-3">
            <img
              src="assets/img/productengineering/re-engineering1.png"
              alt="img"
            ></img>

            <p>System Audit</p>
          </div>
          <div className="product-Product-Development-card1 mx-4">
            <img
              src="assets/img/productengineering/re-engineering2.png"
              alt="img"
            ></img>

            <p>Architecture Review</p>
          </div>
          <div className="product-Product-Development-card1 mx-4">
            <img
              src="assets/img/productengineering/re-engineering3.png"
              alt="img"
            ></img>

            <p>Re-Engineering Strategy</p>
          </div>
          <div className="product-Product-Development-card1 mx-4">
            <img
              src="assets/img/productengineering/re-engineering4.png"
              alt="img"
            ></img>

            <p>Cost-Benefit Analysis</p>
          </div>
          <div className="product-Product-Development-card1 mx-4">
            <img
              src="assets/img/productengineering/re-engineering5.png"
              alt="img"
            ></img>

            <p>Test Case Documentation</p>
          </div>
          <div className="product-Product-Development-card1 mx-4">
            <img
              src="assets/img/productengineering/re-engineering6.png"
              alt="img"
            ></img>

            <p>Feature based Re-Engineering</p>
          </div>

          <div
            className="product-Product-Development-card1 mx-4 "
            id="Continuous-Deployment"
          >
            <img
              src="assets/img/productengineering/re-engineering.png"
              alt="img"
            ></img>

            <p>Continuous Deployment & Testing</p>
          </div>
        </div>
      </div>
      <div className="product-Product-Development container  ">
        <h1 style={{ fontWeight: "bold" }}>Prototype & MVP Development</h1>
        <p>
          Validate your concepts with our rapid prototyping and MVP development
          services, designed to gather market feedback with minimal initial
          investment.
        </p>
        <div className="d-flex justify-content-center col-lg-12 mt-5">
          <div className=" row col-lg-12 g-5 d-flex justify-content-center align-items-center ">
            <div className="mvpcard  col-lg-3">
              <div className="mvpcard-image ">
                <img
                  src="assets/img/productengineering/mvp1.png"
                  alt="img"
                ></img>
              </div>
              <div class="mvpbtn">
                <span class="noselect">User Research</span>
              </div>
            </div>
            <div className="mvpcard col-lg-3">
              <div className="mvpcard-image">
                <img
                  src="assets/img/productengineering/mvp2.png"
                  alt="img"
                ></img>
              </div>
              <div class="mvpbtn " id="mvpbtn1">
                <span class="noselect">Experience Design</span>
              </div>
            </div>
            <div className="mvpcard col-lg-3">
              <div className="mvpcard-image">
                <img
                  src="assets/img/productengineering/endcard3.png"
                  alt="img"
                ></img>
              </div>
              <div class="mvpbtn" id="mvpbtn2">
                <span class="noselect">User Research</span>
              </div>
            </div>
            <div className="mvpcard col-lg-3">
              <div className="mvpcard-image">
                <img
                  src="assets/img/productengineering/mvp3.png"
                  alt="img"
                ></img>
              </div>
              <div class="mvpbtn" id="mvpbtn3">
                <span class="noselect">User Research</span>
              </div>
            </div>
            <div className="mvpcard col-lg-3">
              <div className="mvpcard-image">
                <img
                  src="assets/img/productengineering/re-engineering.png"
                  alt="img"
                ></img>
              </div>
              <div class="mvpbtn" id="mvpbtn4">
                <span class="noselect">User Research</span>
              </div>
            </div>
            <div className="mvpcard col-lg-3">
              <div className="mvpcard-image">
                <img
                  src="assets/img/productengineering/re-engineering5.png"
                  alt="img"
                ></img>
              </div>
              <div class="mvpbtn" id="mvpbtn5">
                <span class="noselect">User Research</span>
              </div>
            </div>
            <div className="mvpcard col-lg-3">
              <div className="mvpcard-image">
                <img
                  src="assets/img/productengineering/mvp4.png"
                  alt="img"
                ></img>
              </div>
              <div class="mvpbtn" id="mvpbtn6">
                <span class="noselect">User Research</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Productengineering;
