import React from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { Link } from "react-router-dom";

const Applicationmodernization = () => {
  return (
    <div>
      <Header />
      <section id="features" class="features">
        <div class="container">
          <header class="section-header" style={{ height: "50px" }}>
            <p>APPLICATION MODERNIZATION</p>
          </header>
          <br></br>
          <h5 style={{ textAlign: "center" }}>
            Prepare Your Business for the Next Digital Frontier: Transforming
            Legacy Systems into Agile, Efficient, and Future-Ready Assets with
            Our Expert Guidance.
          </h5>
          <div class="row feture-tabs">
            <div className="col-lg-6 hero-img" data-aos-delay="200">
              <img
                src="assets/img/modern.avif"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
            <div class="col-lg-6">
              <div class="tab-content">
                <div class="tab-pane fade show active" id="tab1">
                  <p>
                    Modernize Your Business: <b>Harnessing Technology </b>for
                    Success "Unlocking Business Potential: Say Goodbye to Legacy
                    Constraints with Branding World’s Modernization Solutions.
                  </p>
                  <p>
                    Legacy landscapes often hobble businesses, rendering
                    applications inflexible and driving up operational costs due
                    to impending end-of-support issues and system knowledge
                    gaps.
                  </p>
                  <p>
                    But fear not – it's never too early to future-proof your
                    business with Branding World. Our experts analyze your data,
                    align with your goals, and craft a customized plan that
                    optimizes your current applications while seamlessly
                    integrating with emerging technologies.
                  </p>
                  <div class="d-flex align-items-center mb-2">
                    <i class="bi bi-check2"></i>
                    <h4>With Branding World, you can:</h4>
                  </div>
                  <p>
                    - Slash Costs: Modernize to the Cloud and enjoy substantial
                    savings.
                  </p>
                  <div class="d-flex align-items-center mb-2"></div>
                  <p>
                    - Trim Technical Debt: Reduce development time and eliminate
                    legacy burdens.
                  </p>
                  <p>
                    - Streamline Integration: Seamlessly connect disparate
                    systems for improved efficiency.
                  </p>
                  <p>
                    Embrace the future confidently with Branding World – where
                    modernization meets innovation."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="hero"
        className="hero d-flex align-items-center  "
        style={{
          backgroundColor: "hsl(240 45% 98%)",
          justifyContent: "space-evenly",
          display: "flex",
        }}
      >
        <br></br>

        <div className="container">
          <header class="section-header" style={{ height: "50px" , marginBottom:"80px" }}>
            <h1 style={{ textAlign: "center"}}>
              Our Application Modernization Services
            </h1>
          </header>
          
          <div className="row">
            <div
              className="col"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src="assets/img/features-2.png"
                style={{ height: "100px", width: "100px" }}
              ></img>
              <br></br>
              <h3 style={{ color: "#012970" }}>Legacy Modernization:</h3>
              <br></br>
              <ul>
                <li>
                  Revitalize your enterprise with our legacy modernization
                  solutions. Lower costs, mitigate risks, and become digitally
                  ready.
                </li>
              </ul>
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src="assets/img/hero-img.png"
                style={{ height: "100px", width: "100px" }}
              ></img>
              <br></br>
              <h3>Cloud Migration:</h3>
              <br></br>
              <ul>
                <li>
                  Crafting scalable and secure migration strategies tailored to
                  your business needs.
                </li>
              </ul>
            </div>
            <div
              className="col"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img
                src="assets/img/values-1.png"
                style={{ height: "100px", width: "100px" }}
              ></img>
              <br></br>
              <h3>UI/UX Redesign:</h3>
              <br></br>
              <ul>
                <li>
                  Stay relevant and user-friendly in the digital realm with our
                  modern design approach.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id="features" class="features">
        <div class="container" data-aos="fade-up">
        
          <div class="row feature-icons" >
            <h3>Branding World’s Approach to Application Modernization</h3>

            <div class="row">
              <div class="col-xl-8 d-flex content">
                <div class="row align-self-centern pt-4  justify-content-center gy-4">
                  <div class="col-md-6 icon-box" >
                    <i class="ri-command-line"></i>
                    <div>
                      <h4>Application Assessment:</h4>
                      <p>
                        We conduct a thorough analysis of your legacy
                        applications to understand your existing tech stack,
                        interfaces, and dependencies. This assessment forms the
                        foundation for determining the most effective
                        modernization strategies.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box"
                    
                    data-aos-delay="100"
                  >
                    <i class="ri-radar-line"></i>
                    <div>
                      <h4> Evaluating Modernization:</h4>
                      <p>
                        Tailoring solutions to your specific needs, we explore
                        various modernization options aligned with your business
                        requirements.
                      </p>
                    </div>
                  </div>

                  <div
                    class="col-md-6 icon-box"
                  
                    data-aos-delay="200"
                  >
                    <i className="ri-discuss-line icon"></i>
                    <div>
                      <h4>Finalizing Modernization Approach:</h4>
                      <p>
                        Selecting the optimal modernization approach is crucial
                        for maximizing the impact on your organization's
                        mission-critical applications. We meticulously evaluate
                        each option's implications on technology, architecture,
                        functionality, and cost to ensure alignment with your
                        organizational goals and objectives.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-xl-4 text-center"
             
              >
                <img
                  src="assets/img/modern1.png"
                  class="img-fluid p-4"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
     
      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 hero-img" data-aos-delay="200">
              <img
                src="assets/img/modern2.jpg"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center">
            <h2>Our Application Modernization<b> Goals</b></h2>
              <h5>"Join the Vanguard of the Digital Revolution!
</h5>
              <br></br>
              <p>
                    At Branding World, we're dedicated to propelling our clients
                    to the forefront of innovation. Our mission? To empower your
                    business with swift cloud adoption, forging a future-ready
                    enterprise through the magic of intelligent automation,
                    specialized skills, and global delivery capabilities.
                  </p>
                  
                  <p>
                    Picture this: Your team, equipped with our tailored
                    approach, becomes a powerhouse of decision-making prowess.
                    Errors are minimized, information flows seamlessly across
                    all systems, and decisions are made at lightning speed. The
                    result? Your business becomes an engine of innovation,
                    perpetually ahead of the curve.
                  </p>
                  <div class="d-flex align-items-center mb-2"></div>
                  <p>
                  With Branding World by your side, the future isn't just bright – it's positively electrifying."

                  </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <Link
                    to="/ContactUs"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    style={{ borderRadius: "50px" }}
                  >
                    <span style={{ padding: "10px 5px", borderRadius: "50px" }}>
                      Let's Talk Now
                    </span>
                    <i className="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          <div className="row">
            
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h3>How Branding World ensures security during Application Modernization process
</h3>
              <br></br>
              <p data-aos-delay="400" style={{ textAlign: "justify" , color:"#444444"}}>
              At Branding World, we lead the charge in proactive cybersecurity. Our teams collaborate with developers, embedding security measures seamlessly throughout the application modernization process. Stay ahead of threats and ensure a fortified digital transformation with us
              </p>
    
              
            </div>
            <div className="col-lg-6 hero-img" data-aos-delay="200">
              <img
                src="assets/img/modern3.avif"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Applicationmodernization;
