import React from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { Card } from "react-bootstrap";

const Applicationmaintennce = () => {
  return (
    <div>
      <Header />
      <div className="banner">
        <img
          src="assets/img/appma.jpg"
          alt="cybersecurity"
          // style={{ height: "380px", width: "100%", objectFit: "cover" }}
        ></img>
      </div>
      {/* <section id="features" class="features"> */}
      <div class="container">
        <div class="row feture-tabs">
          <div class="col-lg-6 order-2 order-lg-1">
            <h3 style={{ textAlign: "center" }} className="header">
              APPLICATION MAINTENANCE & MANAGEMENT
            </h3>

            <div class="tab-content">
              <div class="tab-pane fade show active" id="tab1">
                <p>
                  Navigating app maintenance and performance monitoring
                  shouldn't be a headache. With us, it's a breeze. Keep your
                  apps up-to-date with cutting-edge features and functionality,
                  ensuring they remain relevant and engaging for your users,
                  every step of the way."
                </p>
                <div class="d-flex align-items-center mb-2">
                  <i class="bi bi-check2"></i>
                  <h4>Unlock Accelerated Transformation with Branding World</h4>
                </div>
                <p>
                  Your IT infrastructure is a complex ecosystem of applications,
                  tools, data, and systems. Managing it effectively demands
                  significant investments of time, resources, and budget. Even
                  meticulously developed applications risk obsolescence without
                  regular assessment and optimization, adding to the maintenance
                  burden faced by businesses today.
                </p>
                <div class="d-flex align-items-center mb-2"></div>
                <p>
                  Enter Branding World. Our application maintenance and
                  management services empower businesses to enhance the speed,
                  efficiency, and performance of their applications. With a team
                  of experts at the helm, we streamline operations, ensuring
                  your applications remain relevant, resilient, and primed for
                  success.
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-lg-6 hero-img order-1 order-lg-2"
            data-aos-delay="200"
          >
            <img
              src="assets/img/features-2.png"
              style={{ borderRadius: "20px" }}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* </section> */}

      <section id="features" className="features">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-4">
              <Card className="custom-card">
                <Card.Header>
                  <h5>Preventive Maintenance</h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    Preventive application maintenance is a sure shot way of
                    ensuring your application is performing at its optimum best,
                    saving you cost and time.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <Card className="custom-card">
                <Card.Header>
                  <h5>Why should you opt for it?</h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    We ensure you get alerts of possible quality issues, crashes
                    or bugs well in advance to solve them before it affects the
                    end user. Branding World guarantees consistent quality and
                    on-time services with timely checks and ensuring everything
                    is up to date.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <Card className="custom-card">
                <Card.Header>
                  <h5>Adaptive Maintenance</h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    Make proactive changes to your application when your
                    software environment undergoes a major shift or during legal
                    policy and compliance changes, so that it remains agile even
                    in evolving conditions.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <Card className="custom-card">
                <Card.Header>
                  <h5>Why should you opt for it?</h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    Branding World does a timely analysis of changes in the
                    operating system, software dependencies, cloud storage or
                    even organizational policies, to be well-prepared to
                    initiate the necessary software maintenance.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <Card className="custom-card">
                <Card.Header>
                  <h5>Corrective Maintenance</h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    One thing you do not want is for your app to malfunction and
                    affect the user’s experience as well as your brand
                    reputation. Corrective maintenance makes sure this is taken
                    care of
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <Card className="custom-card">
                <Card.Header>
                  <h5>Why should you opt for it?</h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    Our team of experts investigates the reported anomalies
                    before making the necessary changes to the app. Following
                    this, extensive testing is carried out to confirm the issues
                    are successfully resolved. Not just that, we’ll help you
                    take care of any issues as they come up so you can focus on
                    your business.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <Card className="custom-card">
                <Card.Header>
                  <h5>Perfective Maintenance</h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    As your business evolves, so do customer expectations with
                    respect to new features they would like to see on the app.
                  </p>
                </Card.Body>
              </Card>
            </div>
            <div className="col-md-6 col-lg-4 mb-4">
              <Card className="custom-card">
                <Card.Header>
                  <h5>Why should you opt for it?</h5>
                </Card.Header>
                <Card.Body>
                  <p className="card-text text-justify">
                    Branding World’s perfective maintenance services enhance your
                    user’s experience and keep your application features
                    relevant to the market needs. We will also assess and
                    suggest the removal of features that are irrelevant or
                    ineffective to the functioning of your application.
                  </p>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>

      <section id="features " className="features bg-primary text-light">
        <header class="section-header">
          <h3>Our Application Maintenance Services</h3>
        </header>

        <div className="container">
          <div className="row">
            {/* First row with 5 divs */}
            <div className="col-md-1 mb-4" style={{ textAlign: "center" }}>
              <img
                src="assets/img/round1.jpg"
                style={{ borderRadius: "50%", height: "100px", width: "100px" }}
              ></img>
              <p>Application Enhancement</p>
            </div>
            <div className="col-md-4 mb-2" style={{ textAlign: "center" }}>
              <img
                src="assets/img/round2.avif"
                style={{ borderRadius: "50%", height: "100px", width: "100px" }}
              ></img>
              <p>
                Application feedback and<br></br> Security reviews
              </p>
            </div>
            <div className="col-md-2 mb-2" style={{ textAlign: "center" }}>
              <img
                src="assets/img/round3.avif"
                style={{ borderRadius: "50%", height: "100px", width: "100px" }}
              ></img>
              <p>
                Error Tracking and<br></br> Debugging
              </p>
            </div>
            <div className="col-md-3 mb-2" style={{ textAlign: "center" }}>
              <img
                src="assets/img/round4.avif"
                style={{ borderRadius: "50%", height: "100px", width: "100px" }}
              ></img>
              <p>
                Customer Response<br></br>
                Mangement
              </p>
            </div>
            <div className="col-md-2 mb-2" style={{ textAlign: "center" }}>
              <img
                src="assets/img/round5.avif"
                style={{ borderRadius: "50%", height: "100px", width: "100px" }}
              ></img>
              <p>Performance Monitoring</p>
            </div>

            {/* Second row with 4 divs */}
            <div className="col-md-3 mb-4" style={{ textAlign: "center" }}>
              <img
                src="assets/img/round6.jpg"
                style={{ borderRadius: "50%", height: "100px", width: "100px" }}
              ></img>
              <p>App Store Management</p>
            </div>
            <div className="col-md-3 mb-4" style={{ textAlign: "center" }}>
              <img
                src="assets/img/round7.jpg"
                style={{ borderRadius: "50%", height: "100px", width: "100px" }}
              ></img>
              <p>Data model migrations</p>
            </div>
            <div className="col-md-3 mb-4" style={{ textAlign: "center" }}>
              <img
                src="assets/img/round81.avif"
                style={{ borderRadius: "50%", height: "100px", width: "100px" }}
              ></img>
              <p>Version upgrades</p>
            </div>
            <div className="col-md-3 mb-4" style={{ textAlign: "center" }}>
              <img
                src="assets/img/round9.jpg"
                style={{ borderRadius: "50%", height: "100px", width: "100px" }}
              ></img>
              <p>Quality Assurance Testing</p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Applicationmaintennce;
