import React from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import "./Home.css";
import { BsGear } from "react-icons/bs";
import { CiUnlock } from "react-icons/ci";
import { TbSpeakerphone } from "react-icons/tb";
import { IoNewspaper } from "react-icons/io5";

import Requestform from "./Requestform";

const Callcenterdailer = () => {
  return (
    <div>
      <Header />
      <div className="callcenter-banner">
        <img
          src="assets/img/callcenterbanner.png"
          alt="cybersecurity"
          style={{
            background:
              "linear-gradient(to bottom right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))",
          }}
        />

        <h1 className="callcenter-banner-text">
          call center
          solution
        </h1>
        <p>
          Modernize your contact centre to facilitate effective communication
          between customers and businesses
        </p>
      </div>
      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          <div className="row ">
            <div className="col-lg-6  callcenter-main-left">
              <h2>Comprehensive Call Centre solution</h2>
              <br></br>
              <Requestform />
              <div data-aos-delay="600">
                <div className="text-center text-lg-start"></div>
              </div>
            </div>
            <div
              className="col-lg-6 callcenter-main-right"
              style={{ display: "flex", textAlign: "center" }}
              data-aos-delay="200"
            >
              <div className="callcenter-main-right-text">
                <div>
                  <img src="assets/img/callcenter/icon1.png" alt="img"></img>
                </div>
                <div>
                  <h5>Omnichnnel Solution</h5>
                  <p>
                    Flexible, scalable platform with omnichannel capabilities
                    for inbound and outbound service.
                  </p>
                </div>
              </div>
              <div className="callcenter-main-right-text">
                <div>
                  <img src="assets/img/callcenter/icon2.png" alt="img"></img>
                </div>
                <div>
                  <h5>Productivity Maximization</h5>
                  <p>
                    Boost Productivity, Increase Satisfaction, Enhance Reporting
                  </p>
                </div>
              </div>
              <div className="callcenter-main-right-text">
                <div>
                  <img src="assets/img/callcenter/icon3.png" alt="img"></img>
                </div>
                <div>
                  <h5>Value for Money</h5>
                  <p>
                    Reduce Downtime, Maintenance, Hardware, Training, and
                    Licensing Costs .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* -----------------------------------------Enterprise-Grade---------------------------- */}
      <div
        className="container Enterprise-Grade
"
      >
        <div className="Enterprise-Grade-heading">
          <h5>Enterprise Grade Call Centre Solution</h5>
          <img
            src="assets/img/callcenter/underline.png"
            className="Enterprise-Grade-heading-img"
            alt="img"
          ></img>
        </div>
        <div className="container Enterprise-Grade-content">
          <div>
            <img src="assets/img/callcenter/icon4.png" alt="img"></img>
            <p>Ease of USe</p>
          </div>
          <div>
            <img src="assets/img/callcenter/icon5.png" alt="img"></img>
            <p>Improved Communcation</p>
          </div>
          <div>
            <img src="assets/img/callcenter/icon6.png" alt="img"></img>
            <p>Scalable Solutions</p>
          </div>
        </div>
      </div>
      {/* -------------------------How We add value---------------------------- */}
      <div className="container add-value">
        <div className="add-value-row">
          <div className="addvalue-row-content">
            <h1>How We add value</h1>
            <p>
              Branding World call center solution is a comprehensive software to
              facilitate effective communication between customers and
              businesses. It serves as a centralized hub where incoming and
              outgoing calls are managed, routed, and monitored efficiently.
              These solutions are crucial for businesses across various
              industries, ranging from customer support to sales and technical
              assistance
            </p>
          </div>
          <div className="addvalue-row-image">
            <img src="assets/img/callcenter/calladd1.png" alt="img"></img>
          </div>
        </div>
      </div>
      {/* -------------------------Key-Features ---------------------------- */}
      <div className="container Key-Features">
        <div className="Key-Features-heading">
          <h1>Key Features of Branding World Call Centre Solution</h1>
        </div>
        <div className="Key-Features-content">
          <div className="outer-box">
            <div className="inner-box">
              <div className="round-box">
                {" "}
                <BsGear style={{ paddingBottom: "5px" }} />
              </div>

              <h5>Omnichannel Integrations</h5>
              <p>
                Seamlessly manage interactions across all channels in one place.
              </p>
            </div>
          </div>
          <div className="outer-box">
            <div className="inner-box">
              <div className="round-box">
                {" "}
                <CiUnlock style={{ paddingBottom: "5px" }} />
              </div>

              <h5>Unified Customer View</h5>
              <p>
                Access complete interaction histories for quicker resolutions.
              </p>
            </div>
          </div>
          <div className="outer-box">
            <div className="inner-box">
              <div className="round-box">
                {" "}
                <TbSpeakerphone style={{ paddingBottom: "5px" }} />
              </div>

              <h5>
                Scalable<br></br>Deployment
              </h5>
              <p>Choose on-premises or cloud solutions to suit your needs.</p>
            </div>
          </div>
          <div className="outer-box">
            <div className="inner-box">
              <div className="round-box">
                {" "}
                <IoNewspaper style={{ paddingBottom: "5px" }} />
              </div>

              <h5>
                Advanced<br></br> Analytics
              </h5>
              <p>
                Gain insights with real-time monitoring and comprehensive
                reports.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* -------------------------Key-Benefits ---------------------------- */}
      <div className="Key-Benefits">
        <div className="Key-Benefits-first">
          <img src="assets/img/callcenter/box-2.png" alt="img"></img>
        </div>
        <div className="Key-Benefits-second">
          <div className="Key-Benefits-second-center">
            <img src="assets/img/callcenter/box-1.png" alt="img"></img>
            <h5>
              {" "}
              <TbSpeakerphone style={{ paddingBottom: "5px" }} />
            </h5>

            <h5>Key Benfits</h5>
          </div>
          <div className="">
            <div className="key-Benefits-second-text-mobile ">
              <h1>Key Benfits</h1>
            </div>
            <div className="Key-Benefits-second-text">
              <div className="Key-Benefitssecond-text-image">
                <img src="assets/img/callcenter/key2.png" alt="img"></img>
                <h3>01</h3>
              </div>
              <div>
                <h5>Omnichnnel Solution</h5>
                <p>
                  Flexible, scalable platform with omnichannel capabilities for
                  inbound and outbound service.
                </p>
              </div>
            </div>
            <div className="Key-Benefits-second-text">
              <div className="Key-Benefitssecond-text-image">
                <img src="assets/img/callcenter/key2.png" alt="img"></img>
                <h3>02</h3>
              </div>
              <div>
                <h5>Productivity Maximization</h5>
                <p>
                  Boost Productivity, Increase Satisfaction, Enhance Reporting
                </p>
              </div>
            </div>
            <div className="Key-Benefits-second-text">
              <div className="Key-Benefitssecond-text-image">
                <img src="assets/img/callcenter/key3.png" alt="img"></img>
                <h3>03</h3>
              </div>
              <div>
                <h5>Value for Money</h5>
                <p>
                  Reduce Downtime, Maintenance, Hardware, Training, and
                  Licensing Costs .
                </p>
              </div>
            </div>
            <div className="Key-Benefits-second-text">
              <div className="Key-Benefitssecond-text-image">
                <img src="assets/img/callcenter/key3.png" alt="img"></img>
                <h3>04</h3>
              </div>
              <div>
                <h5>Value for Money</h5>
                <p>
                  Reduce Downtime, Maintenance, Hardware, Training, and
                  Licensing Costs .
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Key-Benefits-third">
          <img src="assets/img/callcenter/box-2.png" alt="img"></img>
        </div>
      </div>
      {/* ------------------------Last-content ---------------------------- */}
      <div className="container call-center-footer">
        <div className="container call-center-footer-text">
          <h1>GET STARTED TODAY</h1>
          <p>
            Upgrade to a modern, flexible contact center solution. Contact us to
            learn more and schedule a demo
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Callcenterdailer;
