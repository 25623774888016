import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer
      id="footer"
      className="footer"
      style={{ backgroundColor: "rgb(255 255 255)" }}
    >
      <Container>
        <div className="footer-top">
          <Row>
            <Col lg={3} md={12} className="footer-info">
              <Link to="/" className="logo d-flex align-items-center">
                <img
                  src="assets/img/logo/BW.png"
                  alt=""
                  style={{
                    height: "100%",
                    width: "220px",
                    objectFit: "cover",
                  }}
                />
              </Link>
              <p>
              We as Branding World is a professionally managed full service company having more than 10 years of experience in Digital Marketing, Software Development and Mobile App Development
              in Chennai.
              </p>
              {/* <div className="social-links mt-3">
                <Link to="#" className="twitter">
                  <i className="bi bi-twitter"></i>
                </Link>
                <Link to="#" className="facebook">
                  <i className="bi bi-facebook"></i>
                </Link>
                <Link to="#" className="instagram">
                  <i className="bi bi-instagram"></i>
                </Link>
                <Link to="#" className="linkedin">
                  <i className="bi bi-linkedin"></i>
                </Link>
              </div> */}
            </Col>
            <Col lg={3} md={6} className="footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Whowe">Who We Are</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Clients">Clients</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="/Service">Resources</Link>
                </li>
              </ul>
            </Col>
        
            <Col lg={3} md={6} className="footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="#">
                  App Development</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="#">
                  Web Design</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="#">
                   
Web Development
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="#">
                    
Marketing
                  </Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="#">
                  SEO</Link>
                </li>
                <li>
                  <i className="bi bi-chevron-right"></i>{" "}
                  <Link to="#">
                  Promotions</Link>
                </li>
            
              </ul>
            </Col>
            <Col
              lg={3}
              md={12}
              className="footer-contact text-center text-md-start"
            >
              <h4>Contact Us</h4>
              <p>
              Rathinam Complex,
2/12, Avenue Rd, Ponnangipuram, Nungambakkam,
Chennai, Tamil Nadu 600034
                <br></br>
                <strong>Phone:</strong>{" "}
                <Link to="tel:+91 9087097555">+91 9087097555</Link>
                <br></br>
                <strong>Email:</strong>{" "}
                <Link to="mailto:support@brandingworld.net">
                support@brandingworld.net
                </Link>
                <br></br>
              </p>
            </Col>
          </Row>
        </div>

        <div className="footer-bottom text-center">
          <p>
            &copy; Copyright{" "}
            <strong>
              <span>Branding World</span>
            </strong>
            . All Rights Reserved
          </p>
         
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
