import React, { useEffect } from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { Link } from "react-router-dom";

const ContactUs = () => {
  useEffect(() => {
    document.title = "ContactUs | Branding World";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "Great IT field experience Innovative IT solutions to help your business contact now"
      );
  }, []);
  return (
    <div>
      <Header />
      <section id="contact" className="contact mt-5">
        <div className="container">
          <header className="section-header">
            <p>Contact Us</p>
          </header>

          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-geo-alt"></i>
                    <h3>Head Office:</h3>
                    <p>
                    Rathinam Complex,
                   2/12, Avenue Rd, Ponnangipuram, Nungambakkam,
                   Chennai, Tamil Nadu-600034
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-geo-alt"></i>
                    <h3>Branch Office:</h3>
                    <p>
                    Rathinam Complex,
                   2/12, Avenue Rd, Ponnangipuram, Nungambakkam,
                   Chennai, Tamil Nadu-600034
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-telephone"></i>
                    <h3>Call Us</h3>
                    <p>
                      <Link to="tel:+91 9087097555">+91 9087097555</Link>
                    </p>
                    <br></br>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box">
                    <i className="bi bi-envelope"></i>
                    <h3>Email Us</h3>
                    <p>
                      <Link to="mailto:support@brandingworld.net">
                      support@brandingworld.net


                      </Link>
                    </p>
                    <br></br>
                  </div>
                </div>
               
              </div>
            </div>

            <div className="col-lg-6">
              <form action="" method="post" className="php-email-form">
                <div className="row gy-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required
                    />
                  </div>

                  <div className="col-md-6 ">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="6"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>

                  <div className="col-md-12 text-center">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>

                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ContactUs;
