import React, { useState, useEffect } from "react";
import "./Home.css";

import { GetCountries, GetState, GetCity } from "react-country-state-city";
import Apiservice from "./Apiservice";

const Requestform = () => {
  const [countryid, setCountryid] = useState("");
  const [stateid, setStateid] = useState("");
  const [cityid, setCityid] = useState("");

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    city: "",
    state: "",
    country: "",
  });

  useEffect(() => {
    // Fetch the list of countries when the component mounts
    GetCountries().then((result) => {
      setCountriesList(result);
    });
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    Apiservice.submitForm(formData)
      .then((response) => {
        console.log("Form submitted successfully:", response.data);
        alert("Form submitted successfully!");

        // Clear the form
        setFormData({
          first_name: "",
          last_name: "",
          mobile: "",
          email: "",
          city: "",
          state: "",
          country: "",
        });
        // Reset select values
        setCountryid("");
        setStateid("");
        setCityid("");
      })
      .catch((error) => {
        console.error("There was an error submitting the form:", error);
        alert("There was an error submitting the form.");
      });
  };

  const handleCountryChange = (e) => {
    const country = countriesList[e.target.value];
    setCountryid(country.id);
    setFormData((prevData) => ({
      ...prevData,
      country: country.name,
      state: "", // Clear state when country changes
      city: "", // Clear city when country changes
    }));

    GetState(country.id).then((result) => {
      setStateList(result);
      setCityList([]); // Reset city list when country changes
    });
  };

  const handleStateChange = (e) => {
    const state = stateList[e.target.value];
    setStateid(state.id);
    setFormData((prevData) => ({
      ...prevData,
      state: state.name,
      city: "", // Clear city when state changes
    }));

    GetCity(countryid, state.id).then((result) => {
      setCityList(result);
    });
  };

  const handleCityChange = (e) => {
    const city = cityList[e.target.value];
    setCityid(city.id);
    setFormData((prevData) => ({
      ...prevData,
      city: city.name,
    }));
  };
  return (
    <form onSubmit={handleSubmit}>
      <p>*Request form</p>
      <div className="form-row">
        <div className="col-md-12 mb-3">
          <input
            type="text"
            className="form-control"
            id="first_name"
            placeholder="First name"
            value={formData.first_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-12 mb-3">
          <input
            type="text"
            className="form-control"
            id="last_name"
            placeholder="Last name"
            value={formData.last_name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-12 mb-3">
          <input
            type="number"
            className="form-control"
            id="mobile"
            placeholder="Mobile"
            value={formData.mobile}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-md-12 mb-3">
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-md-12 mb-3">
          <select
            className="form-control"
            onChange={handleCountryChange}
            value={
              countriesList.findIndex((c) => c.id === countryid) >= 0
                ? countriesList.findIndex((c) => c.id === countryid)
                : ""
            }
          >
            <option value="" disabled>
              Select Country
            </option>
            {countriesList.map((item, index) => (
              <option key={index} value={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-12 mb-3">
          <select
            className="form-control"
            onChange={handleStateChange}
            value={
              stateList.findIndex((s) => s.id === stateid) >= 0
                ? stateList.findIndex((s) => s.id === stateid)
                : ""
            }
          >
            <option value="" disabled>
              Select State
            </option>
            {stateList.map((item, index) => (
              <option key={index} value={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-12 mb-3">
          {/* <select
                      className="form-control"
                      onChange={handleCityChange}
                      value={cityList.findIndex((c) => c.id === cityid)}
                    >
                      <option value="" disabled>
                        Select City
                      </option>
                      {cityList.map((item, index) => (
                        <option key={index} value={index}>
                          {item.name}
                        </option>
                      ))}
                    </select> */}
          <select
            className="form-control"
            onChange={handleCityChange}
            value={
              cityList.findIndex((c) => c.id === cityid) >= 0
                ? cityList.findIndex((c) => c.id === cityid)
                : ""
            }
          >
            <option value="" disabled>
              Select City
            </option>
            {cityList.map((item, index) => (
              <option key={index} value={index}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <button className="btn btn-primary col-md-12" type="submit">
        Submit
      </button>
    </form>
  );
};

export default Requestform;
