import React from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { Link } from "react-router-dom";

const Cybersecurityservice = () => {
  return (
    <div>
      <Header />

      <div className="banner">
        <img
          src="assets/img/cybersecurity.jpeg"
          alt="cybersecurity"
          // style={{ height: "380px", width: "100%", objectFit: "cover" }}
        ></img>
      </div>

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 hero-img" data-aos-delay="200">
              <img
                src="assets/img/cyber1.webp"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h1>CyberSecurity</h1>
              <br></br>
              <h2
                data-aos-delay="400"
                style={{ textAlign: "justify", color: "#444444" }}
              >
                We have a strong team of experts who can help you in making sure
                you have the most secured system. We are your pathway to global
                security standards
              </h2>
              <br></br>
              <h3 style={{ color: "rgb(86, 56, 255)" }}>
                Perfect Cybersecurity friend as a Partner
              </h3>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <a
                    href="#about"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    style={{ borderRadius: "50px" }}
                  >
                    <span style={{ padding: "10px 5px", borderRadius: "50px" }}>
                      Let's Talk Now
                    </span>
                    <i className="bi bi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="recent-blog-posts" class="recent-blog-posts">
        <div class="container">
          <header class="section-header">
            <p>Cybersecurity</p>
            <br></br>
            <h2>Services</h2>
          </header>
          <div class="row">
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img
                    src="assets/img/cyber2.avif"
                    class="img-fluid"
                    style={{ height: "260px", width: "500px" }}
                    alt=""
                  />
                </div>
                <h2>Application VA/PT</h2>
                <ul style={{ color: "#444444" }}>
                  <li>Web App VA/PT Audit</li>
                  <li>Mobile App VA/PT Audit</li>
                  <li>API VA/PT Audit</li>
                  <li>Thick Client A/PT Adit</li>
                  <li>Secure Code Reveiew</li>
                  <li>Database VA/PT Audit</li>
                  <li>Website VA/PT Audit</li>
                </ul>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img
                    src="assets/img/cyber3.webp"
                    style={{ height: "260px", width: "500px" }}
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <h2>Infra VA/PT</h2>
                <ul style={{ color: "#444444" }}>
                  <li>Host Level VA/PT Audit</li>
                  <li>IOT VA/PT Adit</li>
                  <li>Wireless VA/PT Audit</li>
                  <li>Infra VA/PT Audit</li>
                </ul>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img
                    src="assets/img/cyber4.webp"
                    style={{ height: "260px", width: "500px" }}
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <h2>Other VA/PT</h2>
                <ul style={{ color: "#444444" }}>
                  <li>Cyber Intelligence</li>
                  <li>Vulnerability Audit</li>
                  <li>Cloud VA/PT Audit</li>
                  <li>Cyber Forensics Audit</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="services" className="services">
        <div className="container">
          <header className="section-header">
            <p style={{ fontSize: "50px", lineHeight: "60px" }}>
              CYBERSECURITY VERTICALS
            </p>
            <br></br>
            <h5>THESISCIRCLE Cybersecurity Verticals</h5>
          </header>

          <div
            className="row gy-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-lg-4 col-md-6" data-aos-delay="200">
              <div className="service-box blue">
                <i className="ri-discuss-line icon"></i>
                <h3>Cybersecurity as Service</h3>
                <p>
                  Our expert team safeguards your digital assets with
                  cutting-edge technology and proactive strategies, ensuring
                  your business stays protected from evolving threats.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos-delay="300">
              <div className="service-box orange">
                <i className="ri-discuss-line icon"></i>
                <h3>Cybersecurity as Solutions</h3>
                <p>
                  we provide tailored strategies and technologies to safeguard
                  your digital assets, empowering you to navigate the evolving
                  threat landscape with confidence
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6" data-aos-delay="700">
              <div className="service-box pink">
                <i className="ri-discuss-line icon"></i>
                <h3>Cybersecurity Awareness</h3>
                <p>
                  We equip your organization with the knowledge and skills
                  needed to identify and mitigate potential risks, fostering a
                  culture of proactive security.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos-delay="400">
              <div className="service-box green">
                <i className="ri-discuss-line icon"></i>
                <h3>Cybersecurity as Complince</h3>
                <p>
                  Briskinfosec offers comprehensive cybersecurity solutions,
                  ensuring compliance with industry regulations and safeguarding
                  your digital assets
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6" data-aos-delay="500">
              <div className="service-box red">
                <i className="ri-discuss-line icon"></i>
                <h3>MSSP Support</h3>
                <p>
                  Our Cybersecurity as a Service offers comprehensive protection
                  and support through MSSP expertise, ensuring your systems are
                  fortified against threats and vulnerabilities.
                </p>
                <a href="/Intelligence" className="read-more">
                  <span>Discover Now</span>{" "}
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="features" class="features">
        <div class="container">
          <div class="row feature-icons">
            <h3>Solutions</h3>

            <div class="row">
              <div class="col-xl-4 text-center" data-aos-delay="100">
                <img
                  src="assets/img/cyber6.jpeg"
                  class="img-fluid p-4"
                  alt=""
                />
              </div>

              <div class="col-xl-8 d-flex content">
                <div class="row align-self-center gy-4">
                  <div class="col-md-6 icon-box">
                    <i class="ri-line-chart-line"></i>
                    <div>
                      <h4>Virtual Cyberscurity Team</h4>
                    </div>
                  </div>

                  <div class="col-md-6 icon-box" data-aos-delay="100">
                    <i class="ri-stack-line"></i>
                    <div>
                      <h4>SOC As a Service</h4>
                    </div>
                  </div>

                  <div class="col-md-6 icon-box" data-aos-delay="200">
                    <i class="ri-brush-4-line"></i>
                    <div>
                      <h4>Red Team Service</h4>
                    </div>
                  </div>

                  <div class="col-md-6 icon-box" data-aos-delay="300">
                    <i class="ri-magic-line"></i>
                    <div>
                      <h4>Incident Response Services</h4>
                    </div>
                  </div>

                  <div class="col-md-6 icon-box" data-aos-delay="400">
                    <i class="ri-command-line"></i>
                    <div>
                      <h4>Managed Security Service Provider</h4>
                    </div>
                  </div>

                  <div class="col-md-6 icon-box" data-aos-delay="500">
                    <i class="ri-radar-line"></i>
                    <div>
                      <h4>V-CISO As a Service</h4>
                    </div>
                  </div>
                  <div class="col-md-6 icon-box" data-aos-delay="500">
                    <i className="ri-discuss-line icon"></i>
                    <div>
                      <h4>Third Eye Security Review ssessment</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section id="features" class="features">
        <div class="container">
          <header class="section-header">
            <p>Compliance</p>
            
          </header>

          <div class="row">
           

            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div class="row align-self-center gy-4">
                <div class="col-md-6" data-aos-delay="200">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>HIPAA </h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos-delay="300">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>ISO 27001</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos-delay="400">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>PCIDSS</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos-delay="500">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>GDPR</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos-delay="600">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>CCPA</h3>
                  </div>
                </div>

                <div class="col-md-6" data-aos-delay="700">
                  <div class="feature-box d-flex align-items-center">
                    <i class="bi bi-check"></i>
                    <h3>SSAE</h3>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="col-lg-6">
              <img
                src="assets/img/cyber7.jpg"
                style={{ height: "500px", paddingLeft:"50px" , paddingBottom:"100px"}}
                class="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section> */}

      <section id="portfolio" className="portfolio">
        <div className="container">
          <header className="section-header">
            <p>Compliance</p>
          </header>

          <div className="row gy-4 portfolio-container justify-content-center ms-lg-5">
            {/* Sample portfolio items */}
            <div className="col-lg-4 col-md-6 portfolio-item filter-ecom  justify-content-center align-items-center">
              <div className="portfolio-wrap" style={{ textAlign: "center" }}>
                <img
                  src="assets/img/hipaa.jpeg"
                  style={{ height: "225px", width: "225px" }}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-ecom">
              <div className="portfolio-wrap" style={{ textAlign: "center" }}>
                <img
                  src="assets/img/iso.png "
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-manufacturing">
              <div className="portfolio-wrap" style={{ textAlign: "center" }}>
                <img
                  src="assets/img/pci.png"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            className="row gy-4 portfolio-container justify-content-center ms-lg-5"
            style={{ marginTop: "50px" }}
          >
            {/* Sample portfolio items */}
            <div className="col-lg-4 col-md-6 portfolio-item filter-ecom  justify-content-center align-items-center">
              <div className="portfolio-wrap" style={{ textAlign: "center" }}>
                <img
                  src="assets/img/gdpr.jpg"
                  style={{ height: "225px", width: "225px" }}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-ecom">
              <div className="portfolio-wrap" style={{ textAlign: "center" }}>
                <img
                  src="assets/img/ccpa.jpg "
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 portfolio-item filter-manufacturing">
              <div className="portfolio-wrap" style={{ textAlign: "center" }}>
                <img
                  src="assets/img/ssae.png"
                  className="img-fluid"
                  style={{ height: "225px", width: "225px" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Cybersecurityservice;
