import React from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";

const Ivr = () => {
  return (
    <div>
      <Header />

      <div
        className="container-fluid"
        style={{ backgroundColor: "hsl(240 45% 98%)", paddingTop: "100px" }}
      >
        <div className="container">
          <header className="section-header text-center">
            <p className="header">IVR Systems</p>
            <p>Welcome to Interactive Voice Solutions</p>
            <h5 data-aos-delay="400" style={{ textAlign: "justify" }}>
              At Interactive Voice Solutions, we specialize in providing
              cutting-edge Interactive Voice Response (IVR) systems tailored to
              meet the diverse needs of businesses across industries. With our
              advanced IVR technology, we empower organizations to streamline
              customer interactions, enhance operational efficiency, and deliver
              exceptional service experiences.
            </h5>
          </header>

          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1">
              <h3
                style={{
                  color: "hsl(249 100% 61%)",
                  fontWeight: "800",
                  textAlign: "center",
                }}
              >
                Our Solutions
              </h3>
              <ul>
                <li>
                  Customized IVR Systems: We design and deploy customized IVR
                  systems that cater to the unique requirements of your
                  business. Whether you need to provide customers with order
                  status updates, appointment reminders, account information, or
                  any other type of service, our IVR solutions are designed to
                  deliver seamless and intuitive experiences.
                </li>
                <li>
                  Scalable and Flexible: Our IVR systems are built to scale with
                  your business, allowing you to adapt to changing needs and
                  growing customer demands. Whether you're a small startup or a
                  large enterprise, our solutions can accommodate your needs
                  with ease.
                </li>
                <li>
                  Advanced Technology: Powered by state-of-the-art Digital
                  Signal Processing (DSP) hardware, our IVR systems deliver
                  unparalleled voice quality and accuracy. Our technology
                  analyzes voice patterns with precision, ensuring clear and
                  effective communication with your customers.
                </li>
                <li>
                  Easy Integration: Integrating our IVR systems with your
                  existing phone system is seamless and hassle-free. We work
                  closely with your team to ensure smooth integration and
                  minimal disruption to your operations.
                </li>
              </ul>
            </div>
            <div className="col-lg-6 d-flex justify-content-center align-items-center order-1 order-lg-2">
              <img
                src="assets/img/ivr.jpg"
                style={{
                  borderRadius: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="container"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="row">
          <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-center order-2 order-lg-1">
            <h3
              style={{
                color: "hsl(249 100% 61%)",
                fontWeight: "800",
                textAlign: "center",
              }}
            >
              Benefits of IVR Systems
            </h3>
            <ul>
              <li>
                Enhanced Customer Experience: Provide customers with quick
                access to information and services, reducing wait times and
                improving satisfaction.
              </li>
              <li>
                Increased Efficiency: Automate routine inquiries and tasks,
                allowing your team to focus on more complex issues and strategic
                initiatives.
              </li>
              <li>
                24/7 Accessibility: With our IVR systems, customers can access
                information and services round-the-clock, enhancing convenience
                and accessibility.
              </li>
              <li>
                Cost Savings: By automating processes and reducing the need for
                human intervention, our IVR solutions help lower operational
                costs and increase ROI.
              </li>
            </ul>
          </div>
          <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center order-1 order-lg-2">
            <img
              src="assets/img/ivrbenefits2.jpg"
              style={{ borderRadius: "20px", maxWidth: "100%", height: "auto" }}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1">
              <h3
                style={{
                  color: "hsl(249 100% 61%)",
                  fontWeight: "800",
                  textAlign: "center",
                }}
              >
                Why Choose Interactive Voice Solutions?
              </h3>
              <ul>
                <li>
                  Expertise: With years of experience in the industry, our team
                  of experts has the knowledge and skills to deliver IVR
                  solutions that exceed expectations.
                </li>
                <li>
                  Customization: We understand that every business is unique,
                  which is why we offer fully customizable IVR solutions
                  tailored to your specific needs and requirements.
                </li>
                <li>
                  Reliability: Our IVR systems are built for reliability and
                  performance, ensuring uninterrupted service and peace of mind
                  for your business.
                </li>
                <li>
                  Customer Support: We're committed to providing exceptional
                  customer support every step of the way, from initial
                  consultation to ongoing maintenance and support.
                </li>
              </ul>
            </div>
            <div className="col-lg-6 d-flex justify-content-center align-items-center order-1 order-lg-2">
              <img
                src="assets/img/ivrvoice.jpg"
                style={{
                  borderRadius: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Ivr;
