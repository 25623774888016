import React from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";

const Ippbx = () => {
  return (
    <div>
      <Header />

      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)", minHeight: "800px" }}
      >
        <div className="container">
          <header className="section-header">
            <h2>VAC IPPBX</h2>
          </header>

          <div className="row">
            <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-center order-2 order-lg-1">
              <h3 style={{ color: "hsl(249 100% 61%)", fontWeight: "800" }}>
                IPPBX
              </h3>

              <p>
                An IP PBX (Internet Protocol Private Branch Exchange) is a type
                of private branch exchange (PBX) phone system that connects
                telephone extensions to the public switched telephone network
                (PSTN) and provides internal communication for a business or
                organization via Internet Protocol (IP) networks. It uses Voice
                over Internet Protocol (VoIP) technology for making and
                receiving calls, transmitting voice data over the internet or
                internal IP network rather than traditional phone lines.
              </p>
              <p>
                VAC IP PBX systems offer numerous advantages over traditional
                PBX systems, including cost savings, scalability, flexibility,
                and advanced features such as voicemail, auto-attendant, call
                forwarding, conference calling, and more. They are highly
                suitable for businesses of all sizes, from small units to large
                enterprises, looking to modernize their communication
                infrastructure.
              </p>
              <p>
                VAC IP PBX systems comes with a wide range of features designed
                to enhance communication within organizations. Here are some
                common features you'll find in an IP PBX system:
              </p>
            </div>
            <div
              className="col-lg-6 col-md-12 hero-img order-1 order-lg-2"
              style={{ textAlign: "center" }}
            >
              <img
                src="assets/img/ippex.webp"
                style={{
                  borderRadius: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 hero-img" data-aos-delay="200">
              <img
                src="assets/img/ippbx1.jpg"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Calling</b> VAC IPPBX Software enables you to make and
                receive calls over the internet or internal IP network, reducing
                costs associated with traditional phone lines.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Extension Dialing </b> Users can dial internal extensions to
                reach colleagues within the organization easily.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                Our IPPBX enable users to have IP Phones on LAN network, analog
                phones with gateway interface and android phones with wifi.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Interactive Voice Response (IVR)</b>
                VAC IPPBX greets and directs callers, thru an automated voice
                menu system, to the appropriate department or extension.
                Greeting message can be customized.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Voicemail</b>Our software allows you to record and manage
                voicemail messages, accessible through phones or web interfaces.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Call Transfer</b>
                You can transfer calls to other extensions or external numbers
                seamlessly.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Call Forwarding</b>
                VAC IPPBX helps you to forward calls to other extensions or
                external numbers based on predefined conditions or user
                preferences.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Call Recording</b>
                Our IPPBX software gives you the flexibility to record incoming
                or outgoing calls for quality assurance, training, or compliance
                purposes.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1">
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Conference</b>
                VAC IPPBX helps you to create multi conference bridges with
                internal and external participants. Such conferences can also be
                recorded and shared with other stake holders who could not to
                participate in the conference.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Unified Messaging</b> You can access voicemail, email, and
                fax messages from a single interface in our VAC IPPBX.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Presence Management </b> See the availability status of
                colleagues (e.g., available, busy, away) to determine the best
                time to reach them.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Call Queuing</b>Manage incoming calls during peak times by
                placing them in a queue and playing customizable messages or
                music while waiting.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Call Monitoring and Barging</b>
                Supervisors can monitor live calls and intervene if necessary,
                or join the call silently for training or assistance.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Integration with CRM and other Business Applications</b>{" "}
                Integration with customer relationship management (CRM) systems
                or other business applications for improved productivity and
                customer service.
              </p>
            </div>
            <div
              className="col-lg-6 hero-img order-1 order-lg-2"
              data-aos-delay="200"
            >
              <img
                src="assets/img/ippbx2.avif"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section
        id="hero"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 hero-img" data-aos-delay="200">
              <img
                src="assets/img/ippbx3.jpg"
                style={{ borderRadius: "20px" }}
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Security Features</b> Encryption, access control, and
                authentication mechanisms to ensure the security of
                communications
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Scalability</b> Easily add or remove extensions and features
                as the organization grows or changes.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                Our IPPBX enable users to have IP Phones on LAN network, analog
                phones with gateway interface and android phones with wifi.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Mobility</b>
                Support for mobile devices, allowing employees to stay connected
                even when away from the office.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Call Analytics and Reporting</b>Generate reports on call
                volume, duration, and other metrics for performance evaluation
                and optimization.
              </p>
              <p data-aos-delay="400" style={{ textAlign: "justify" }}>
                <b>Interconnect your existing EPABX </b>for analog extensions.
                <br></br>
                These features can vary depending on the specific IP PBX system
                and the needs of the organization. Many IP PBX systems offer
                customization options to tailor the features to the unique
                requirements of each business.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Ippbx;
