import axios from "axios";

const Apiservice = {
  submitForm: (formData) => {
    return axios.post("https://thesis.bwsoft.in/api/api/contact/", formData);
  },
  fetchProductList: () => {
    return axios.get("https://thesis.bwsoft.in/api/api/product-list/");
  },
};

export default Apiservice;
