import React from "react";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { Link } from "react-router-dom";
import { Container, Row, Col, Table } from "react-bootstrap";
import { FaRegFileLines } from "react-icons/fa6";
import { LiaAddressCardSolid } from "react-icons/lia";
import { PiSquaresFour } from "react-icons/pi";
import { CiMicrophoneOn } from "react-icons/ci";
import { FaSkating } from "react-icons/fa";
import { ImHeadphones } from "react-icons/im";

const Animation = () => {
  return (
    <div>
      <Header />
      <section
        id="hero"
        className="d-flex align-items-center"
        style={{
          backgroundColor: "rgb(98 154 255)",
          minHeight: "100vh",
          border: "none",
          boxShadow: "none",
        }}
      >
        <Container>
          <Row>
            <Col lg={6} className="d-flex flex-column justify-content-center">
              <h1
                style={{ color: "white", fontSize: "clamp(2rem, 8vw, 4rem)" }}
              >
                You are not utilizing the full potential of video marketing for
                your brand
              </h1>
              <p
                data-aos-delay="400"
                style={{ color: "black", fontSize: "clamp(1rem, 4vw, 2rem)" }}
              >
                Place your confidence in our professional offering
              </p>
              <div data-aos-delay="600">
                <div className="text-center text-lg-start">
                  <Link
                    to="/ContactUs"
                    className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      padding: "10px 20px",
                      borderRadius: "5px",
                      fontSize: "clamp(0.8rem, 2vw, 1.5rem)",
                    }}
                  >
                    <span>Let's Talk Now</span>
                    <i className="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={6} className="hero-img" data-aos-delay="200">
              <img
                src="assets/img/ani3.png"
                style={{ borderRadius: "20px", width: "100%", height: "auto" }}
                className="img-fluid"
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section
        id="service"
        className="hero d-flex align-items-center"
        style={{ backgroundColor: "hsl(240 45% 98%)" }}
      >
        <Container>
          <Row>
            <Col lg={6} className="d-flex flex-column justify-content-center">
              <h1>
                Our Se
                <span style={{ backgroundColor: "rgb(255, 187, 11)" }}>
                  rvice
                </span>
              </h1>
              <ul
                style={{
                  listStyleType: "none",
                  fontSize: "clamp(1rem, 2vw, 2rem)",
                }}
              >
                <li>
                  <i
                    className="bi bi-arrow-right"
                    style={{ paddingRight: "10px" }}
                  ></i>{" "}
                  SaaS Product Demo
                </li>
                <li>
                  <i
                    className="bi bi-arrow-right"
                    style={{ paddingRight: "10px" }}
                  ></i>{" "}
                  Explainer Video
                </li>
                <li>
                  <i
                    className="bi bi-arrow-right"
                    style={{ paddingRight: "10px" }}
                  ></i>{" "}
                  Corporate Videos
                </li>
                <li>
                  <i
                    className="bi bi-arrow-right"
                    style={{ paddingRight: "10px" }}
                  ></i>{" "}
                  Commercial Ad Films
                </li>
                <li>
                  <i
                    className="bi bi-arrow-right"
                    style={{ paddingRight: "10px" }}
                  ></i>{" "}
                  Spokesperson Videos
                </li>
                <li>
                  <i
                    className="bi bi-arrow-right"
                    style={{ paddingRight: "10px" }}
                  ></i>{" "}
                  Testimonial Videos
                </li>
              </ul>
            </Col>
            <Col lg={6} className="hero-img" data-aos-delay="200">
              <img
                src="assets/img/ani.png"
                style={{
                  borderRadius: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
                className="img-fluid"
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container fluid style={{ width: "80%" }}>
          <Row xs={1} md={2} lg={3} className="g-4">
            <Col>
              <div
                style={{
                  backgroundColor: "rgb(124, 65, 251)",
                  textAlign: "center",
                  height: "200px",
                  borderRadius: "10px",
                  marginBottom: "20px",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "clamp(1rem, 4vw, 2rem)",
                }}
              >
                <p>
                  Collecting <br />
                  information
                </p>
                <FaRegFileLines size={70} style={{ marginLeft: "20px" }} />
              </div>
            </Col>
            <Col>
              <div
                style={{
                  backgroundColor: "#FF69B4",
                  textAlign: "center",
                  height: "200px",
                  borderRadius: "10px",
                  marginBottom: "20px",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "clamp(1rem, 4vw, 2rem)",
                }}
              >
                <p>
                  Script <br />
                  writing
                </p>
                <LiaAddressCardSolid size={70} style={{ marginLeft: "20px" }} />
              </div>
            </Col>
            <Col>
              <div
                style={{
                  backgroundColor: "rgb(255, 187, 11)",
                  textAlign: "center",
                  height: "200px",
                  borderRadius: "10px",
                  marginBottom: "20px",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "clamp(1rem, 4vw, 2rem)",
                }}
              >
                <p>storyboard</p>
                <PiSquaresFour size={70} style={{ marginLeft: "20px" }} />
              </div>
            </Col>
            <Col>
              <div
                style={{
                  backgroundColor: "rgb(255, 66, 104)",
                  textAlign: "center",
                  height: "200px",
                  borderRadius: "10px",
                  marginBottom: "10px",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "clamp(1rem, 4vw, 2rem)",
                }}
              >
                <p>
                  VOICEOVER <br />
                  RECORDING
                </p>
                <CiMicrophoneOn size={70} style={{ marginLeft: "20px" }} />
              </div>
            </Col>
            <Col>
              <div
                style={{
                  backgroundColor: "#6495ED",
                  textAlign: "center",
                  height: "200px",
                  borderRadius: "10px",
                  marginBottom: "10px",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "clamp(1rem, 4vw, 2rem)",
                }}
              >
                <p>
                  Designing and <br />
                  animation
                </p>
                <FaSkating size={70} style={{ marginLeft: "20px" }} />
              </div>
            </Col>
            <Col>
              <div
                style={{
                  backgroundColor: "rgb(30, 200, 146)",
                  textAlign: "center",
                  height: "200px",
                  borderRadius: "10px",
                  marginBottom: "10px",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "clamp(1rem, 4vw, 2rem)",
                }}
              >
                <p>
                  Sfx and <br />
                  music
                </p>
                <ImHeadphones size={70} style={{ marginLeft: "20px" }} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section style={{ height: "100vh" }}>
        <Container fluid style={{ height: "100%" }}>
          <Row className="h-100">
            <Col md={4} className="d-flex flex-column bg-primary text-light">
              <div className="p-5">
                <h3>
                  <b>Dear customer, please note it</b>
                </h3>
                <ol style={{ paddingLeft: "20px" }}>
                  <li style={{ marginBottom: "10px" }}>
                    Dear esteemed clients, we kindly request that you allocate
                    sufficient time to thoroughly evaluate and confirm your
                    well-defined concept.
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Subsequently, let us commence the initiation of the
                    animation endeavor
                  </li>
                  <li style={{ marginBottom: "10px" }}>
                    Ultimately, our quality assurance department examined the
                    video specifications and the delivered output provided to
                    the clients.
                  </li>
                </ol>
              </div>
            </Col>
            <Col
              md={8}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <div className="p-4">
                <img
                  src="assets/img/ani2.png"
                  className="img-fluid"
                  alt="animation"
                />
                <p style={{ textAlign: "justify" }}>
                  Dear esteemed client, I am confident in the growth of your
                  business, and I would like to express my sincere gratitude for
                  your continued patronage
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </div>
  );
};

export default Animation;
